import Actions from '@/components/actions';
import EnhancedDisplay from '@/components/enhancedDisplay';
import FormattedTextField from '@/components/formattedTextField';
import PageWrapper from '@/components/page/wrapper';
import SearchBar from '@/components/searchBar';
import { axiosClient } from '@/data/axios';
import currencyFormat from '@/helpers/currencyFormat';
import ReceivingUomScanner, {
	scanUomsToItems,
} from '@/pages/dashboard/commerce/purchases/scanAndReceive/receivingUomScanner';
import { useModalControls } from '@/providers/modal';
import {
	AddCircleRounded as AddCircleRoundedIcon,
	Close as CloseIcon,
	Delete as DeleteIcon,
} from '@mui/icons-material';
import { IconButton, ListItemText, Stack, Typography } from '@mui/material';
import Bluebird from 'bluebird';
import { isEmpty } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncEffect, useDebouncedValue } from 'rooks';

export type ScannedReceivingItem = {
	id: string,
	name: string,
	externalId: string,
	uom: {
		id: string,
		quantity: number,
		unit: string,
		sku: string,
		cost: number,
		code: string,
		selected: boolean
	},
	receivedQuantity: number
};

export default function ScanAndReceive() {
	
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { closeModal } = useModalControls();
	
	const [ scannedItems, setScannedItems ] = useState<ScannedReceivingItem[]>( [] );
	const [ search, setSearch ] = useState<string>( '' );
	const [ debouncedSearch, setDebouncedSearch ] = useDebouncedValue<string | ''>( search, 500 );
	
	useAsyncEffect( async () => {
		if ( debouncedSearch ) {
			try {
				await scanUomsToItems( scannedItems, setScannedItems, debouncedSearch );
				await new Audio( '/sounds/scanner-sound.mp3' ).play();
				setDebouncedSearch( '' );
				setSearch( '' );
			} catch {
				enqueueSnackbar( t( 'common:no-items' ) );
				return;
			}
		}
	}, [ debouncedSearch ] );
	
	return (
		<PageWrapper
			hideBack
			modalWrapper
			maxWidth='xl'
			boxProps={{ sx: { height: '100vh', bgcolor: 'background.default' } }}
			primary='Scan to update Stock'
			icon={(
				<IconButton onClick={closeModal}>
					<CloseIcon/>
				</IconButton>
			)}
			actions={(
				<Actions
					max={1}
					items={[
						{
							name    : 'Add Stock',
							disabled: isEmpty( scannedItems ),
							icon    : <AddCircleRoundedIcon/>,
							onClick : async () => {
								
								await Bluebird.map(
									scannedItems,
									async ( { externalId, uom, receivedQuantity } ) => {
										await axiosClient.post( '/api/user/inventory/updateUom', {
											externalId,
											diff  : receivedQuantity,
											action: 'Purchase Scan Receiving',
											uom   : {
												id      : uom.id,
												selected: uom.selected || false,
												quantity: uom.quantity || 0,
											},
										} );
									},
									{ concurrency: 2 },
								);
								
								closeModal();
							},
						},
					]}
				/>
			)}>
			<Stack spacing={1}>
				<SearchBar
					fullWidth
					autoFocus
					value={search}
					size='medium'
					placeholder='Scan by sku or code'
					sx={{ py: 2 }}
					onChange={( e ) => setSearch( e.target.value )}
				/>
				<ReceivingUomScanner
					scanOnPageLoad
					scannedItems={scannedItems}
					setScannedItems={setScannedItems}
				/>
				<EnhancedDisplay<ScannedReceivingItem>
					data={scannedItems}
					listProps={{
						renderRow: ( item, index ) => (
							<Stack direction='row' width='100%'>
								<ListItemText
									primary={item.name}
									primaryTypographyProps={{ variant: 'h5' }}
									secondary={(
										<Stack spacing={1}>
											<Stack>
												<Typography>
													Name: {item.name}
												</Typography>
												<Typography>
													Code: {item.uom?.code}
												</Typography>
												<Typography>
													Stock: {item.uom?.quantity}
												</Typography>
												<Typography>
													Cost: {currencyFormat( item.uom.cost )}
												</Typography>
												<Typography>
													Sku: {item.uom.sku}
												</Typography>
											</Stack>
											<FormattedTextField
												key='receivedQuantity'
												fullWidth
												value={item.receivedQuantity}
												type='number'
												InputProps={{ inputProps: { min: 0 } }}
												onFocus={( e ) => e.target.select()}
												onChange={( e ) => {
													const receivedQuantity = Math.ceil( Math.abs( +e.target.value ) );
													
													setScannedItems( scannedItems.map( ( item, i ) => {
														if ( i === index ) {
															return { ...item, receivedQuantity: receivedQuantity || 0 };
														}
														return item;
													} ) );
												}}
											/>
											<IconButton
												sx={{ p: .5, ml: .5 }}
												color='error'
												onClick={() => setScannedItems( scannedItems.filter( ( _, i ) => i !== index ) )}>
												<DeleteIcon sx={{ fontSize: 20 }}/>
											</IconButton>
										</Stack>
									)}
								/>
							</Stack>
						),
					}}
					tableProps={{
						hover  : false,
						headers: [
							t( 'common:items' ),
							t( 'common:code' ),
							t( 'common:stock' ),
							t( 'common:cost' ),
							t( 'common:sku' ),
							t( 'common:received' ),
							'Action',
						],
						columns: ( item, index ) => [
							<Typography key='name'>
								{item.name}
							</Typography>,
							<Typography key='code'>
								{item.uom?.code}
							</Typography>,
							<Typography key='stock'>
								{item.uom?.quantity || 0}
							</Typography>,
							<Typography key='cost'>
								{currencyFormat( item.uom?.cost )}
							</Typography>,
							<Typography key='sku'>
								{item.uom?.sku}
							</Typography>,
							<FormattedTextField
								key='receivedQuantity'
								fullWidth
								value={item.receivedQuantity}
								type='number'
								InputProps={{ inputProps: { min: 0 } }}
								onFocus={( e ) => e.target.select()}
								onChange={( e ) => {
									const receivedQuantity = Math.ceil( Math.abs( +e.target.value ) );
									
									setScannedItems( scannedItems.map( ( item, i ) => {
										if ( i === index ) {
											return { ...item, receivedQuantity: receivedQuantity || 0 };
										}
										return item;
									} ) );
								}}
							/>,
							<IconButton
								key='delete'
								sx={{ p: .5, ml: .5 }}
								color='error'
								onClick={() => setScannedItems( scannedItems.filter( ( _, i ) => i !== index ) )}>
								<DeleteIcon sx={{ fontSize: 20 }}/>
							</IconButton>,
						],
						cellProps: [
							{ width: '30%' },
							{ width: '10%' },
							{ width: '10%' },
							{ width: '10%' },
							{ width: '10%' },
							{ width: '20%' },
							{ width: '10%', align: 'right' },
						],
					}}
				/>
			</Stack>
		</PageWrapper>
	);
}
