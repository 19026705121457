import SubscriptionModal from '@/baseline/subscription';
import UpgradeIcon from '@/baseline/subscription/upgradeIcon';
import useTierPermission from '@/baseline/subscription/useTierPermission';
import Actions from '@/components/actions';
import { mutateGraphQL } from '@/data/apollo';
import {
	PurchaseWrite_UseCraeteNewPurchaseMutation,
	PurchaseWrite_UseCraeteNewPurchaseMutationVariables,
} from '@/generated/graphql';
import idPick from '@/helpers/idPick';
import PurchaseReceivingCreationModal from '@/modals/purchaseReceiving/purchaseReceivingCreationModal';
import { stayOpen } from '@/pages/dashboard/commerce/components/tableHelpers';
import { getNewCommerceCompanyLocation } from '@/pages/dashboard/commerce/orders/actions/pageWrapperActions';
import ScanAndReceive from '@/pages/dashboard/commerce/purchases/scanAndReceive';
import { useCloverLocations, useLocations } from '@/pages/formSelects/locationSelect';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { gql } from '@apollo/client';
import { AddCircle as AddCircleIcon, QrCode2 as QrCode2Icon } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

export function useCreateNewPurchase( preselectedItems?: any ) {
	const router = useRouter();
	const { staff } = useUserInfo();
	const queryClient = useQueryClient();
	const [ locations ] = useLocations();
	const [ cloverLocations ] = useCloverLocations();
	const location = getNewCommerceCompanyLocation( staff!, locations, cloverLocations );
	
	return async () => {
		try {
			let lineItems = [];
			if ( preselectedItems ) {
				lineItems = preselectedItems.map( ( lineItem ) => ( {
					...idPick( lineItem, [
						'name',
						'price',
						'image',
						'description',
						'unit',
						'externalId',
						'metadata',
					] ),
					quantity: 1,
					code    : lineItem.code ?? undefined,
					uom     : lineItem.uomId ?? undefined,
					item    : lineItem.item?.id ?? undefined,
				} ) );
			}
			const { purchaseWrite } = await mutateGraphQL<PurchaseWrite_UseCraeteNewPurchaseMutationVariables, PurchaseWrite_UseCraeteNewPurchaseMutation>( {
				mutation: gql`
					mutation PurchaseWrite_UseCraeteNewPurchase($input: PurchaseValidator, $method: String, $customNumber: Boolean) {
						purchaseWrite(input: $input,method: $method, customNumber: $customNumber) {
							id
						}
					}
				`,
				variables: {
					customNumber: Boolean( staff?.company.metadata?.customPurchaseNumber ),
					method      : 'New Purchase',
					input       : {
						staff          : staff?.id,
						companyLocation: location?.id || null,
						serviceDate    : new Date(),
						lineItems      : lineItems,
					},
				},
			} );
			
			if ( preselectedItems ) {
				window.open( `/dashboard/commerce/purchases/${purchaseWrite.id}/edit`, '_blank' );
			} else {
				await router.push( `/dashboard/commerce/purchases/${purchaseWrite.id}/edit` );
				await queryClient.invalidateQueries( [ 'user' ] );
			}
			
		} catch ( e ) {
			throw e;
		}
	};
}

export default function PurchasePageWrapperActions() {
	const creatable = usePermissions( permissions.purchases.write );
	const createNewPurchase = useCreateNewPurchase();
	const { t } = useTranslation();
	const { showModal } = useModal();
	const subscriptionIsValid = useTierPermission( 'PURCHASING' );
	const scannerButtonsSubscriptionIsValid = useTierPermission( 'SCANNER_SUPPORT' );
	
	return (
		<Actions
			separated
			max={3}
			items={[ creatable && {
				name       : `${t( 'common:new' )} ${t( 'commerce:purchases' )}`,
				icon       : <AddCircleIcon/>,
				endNode    : !subscriptionIsValid && <UpgradeIcon variant='inverted' permission='PURCHASING'/>,
				buttonProps: { variant: 'contained', color: 'primary' },
				onClick    : async () => {
					if ( !subscriptionIsValid ) return showModal( SubscriptionModal, { variant: 'fullPageDialog' } );
					await createNewPurchase();
				},
			}, creatable && {
				name       : `${t( 'common:create' )} ${t( 'commerce:receiving' )}`,
				icon       : <AddCircleIcon/>,
				buttonProps: { variant: 'outlined', color: 'primary' },
				onClick    : () => showModal(
					PurchaseReceivingCreationModal,
					{ maxWidth: 'xl', fullPageModal: true, onClose: ( event, reason ) => stayOpen( event, reason ) } ),
			}, creatable && {
				name   : 'Scan & Receive',
				icon   : <QrCode2Icon/>,
				endNode: !scannerButtonsSubscriptionIsValid && <UpgradeIcon permission='SCANNER_SUPPORT'/>,
				onClick: () => {
					if ( !scannerButtonsSubscriptionIsValid ) return showModal( SubscriptionModal, { variant: 'fullPageDialog' } );
					return showModal( ScanAndReceive, { variant: 'fullPageDialog' } );
				},
			} ]}
		/>
	);
}
