import ScannerButtons from '@/components/scannerButtons';
import { queryGraphQL } from '@/data/apollo';
import { UomRead } from '@/graphql/uom.graphql';
import { ScannedReceivingItem } from '@/pages/dashboard/commerce/purchases/scanAndReceive/index';
import { QueryUomReadArgs } from '@/types/schema';
import { Theme, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { validate } from 'uuid';

export const scanUomsToItems = async (
	scannedItems: ScannedReceivingItem[],
	setScannedItems: ( items: ScannedReceivingItem[] ) => void,
	scannedCode: string,
) => {
	const { uomRead } = await queryGraphQL<QueryUomReadArgs>( {
		query    : UomRead,
		variables: validate( scannedCode ) ? { id: scannedCode } : { scannedCode },
	} );
	
	if ( uomRead.item?.id ) {
		const item = uomRead.item;
		const sameItem = scannedItems.find( ( item ) => item.uom?.id === uomRead.id );
		
		if ( sameItem ) {
			setScannedItems( scannedItems.map( ( item ) => {
				if ( item.uom?.id === uomRead.id ) {
					return {
						...item,
						receivedQuantity: ( item.receivedQuantity || 0 ) + 1,
					};
				}
				return item;
			} ) );
			return;
		}
		
		setScannedItems( [ ...scannedItems, {
			id        : item.id,
			name      : item.name || '',
			externalId: item.externalId || '',
			uom       : {
				id      : uomRead.id,
				quantity: uomRead.quantity || 0,
				unit    : uomRead.sku || '',
				sku     : uomRead.sku || '',
				cost    : uomRead.cost || 0,
				code    : uomRead.code || '',
				selected: uomRead.selected ?? false,
			},
			receivedQuantity: 1,
		} ] );
		
	}
};

export default function ReceivingUomScanner( {
	scanOnPageLoad,
	scannedItems,
	setScannedItems,
}: {
	scanOnPageLoad?: boolean,
	scannedItems: ScannedReceivingItem[],
	setScannedItems: ( item: ScannedReceivingItem[] ) => void
} ) {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	if ( !isMobile ) return null;
	return (
		<ScannerButtons
			scanOnPageLoad={scanOnPageLoad}
			onRead={async ( scannedCode ) => {
				await scanUomsToItems( scannedItems, setScannedItems, scannedCode )
					.then( async () => {
						await new Audio( '/sounds/scanner-sound.mp3' ).play();
					} )
					.catch( ( e ) => {
						enqueueSnackbar( t( 'common:no-items' ) );
						return;
					} );
			}}
		/>
	);
}
